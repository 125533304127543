import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faPhone,faLocationDot } from '@fortawesome/free-solid-svg-icons';
import useLazyLoad from './useLazyLoad ';
export default function FooterComponent() {
  const [active, setActive] = useState('#home');
    const handleItemClick = (id) => {
        setActive(id);
    };
    const elementsRef = useLazyLoad();
    return (
        <div className='footer'>
          <div className='container'>
            <div className='footer-section'>
              <div className='row'>
                <div className='col-md-4 col-sm-12 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                  <div className='d-flex' >
                    <span className='logo-title'>
                <a href="#home"><img src="logo/MOCA-320X135.png" width="180" alt="Logo" /></a>
                </span>
                    <span className='round-buttons-container ms-5'>
                      <span className='round-button yellow'></span>
                      <span className='round-button green'></span>
                    </span>
                  </div>
                  <p className='description mt-3 ms-2'>Our Mission: To bridge the gap between MSMEs and accessible working capital.</p>
                </div>
                <div className='col-md-2 col-sm-12 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                  <ul className='menu-list'>
                    <li><span className='sub-heading'>Company</span></li>
                    <li className='mt-3'> <a href="#home" onClick={() => handleItemClick('#home')}>Home</a></li>
                    <li className='mt-2'> <a href="#about" onClick={() => handleItemClick('#about')}>About Us</a></li>
                    <li className='mt-2'><a href="#services" onClick={() => handleItemClick('#services')}>Services</a></li>
                    <li className='mt-2'><a href="#whyus" onClick={() => handleItemClick('#whyus')}>Why Us</a></li>
                    <li className='mt-2'><a href="#testimonial" onClick={() => handleItemClick('#testimonial')}>Testimonial</a></li>
                  </ul>
                </div>
                <div className='col-md-2 col-sm-12 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                  <ul className='menu-list'>
                    <li><span className='sub-heading'>Services</span></li>
                    <li className='mt-3'>Business Loan</li>
                    <li className='mt-2'>Credit Line</li>
                    <li className='mt-2'>Financial Planning</li>
                    <li className='mt-2'>Consultation</li>
                  </ul>
                </div>
                <div className='col-md-4 col-sm-12 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                  <ul className='menu-list'>
                    <li><span className='sub-heading'>Contact Us</span></li>
                    <li className='mt-3'>Our Support and Sales team is available<br/> 24 /7 to answer your queries</li>
                    <li className='mt-2'><FontAwesomeIcon icon={faLocationDot} className='phone-icon'/>51/1702, First Floor,Civil Lane Rd. TCR, KL - IN </li>
                    <li className='mt-2'> <FontAwesomeIcon icon={faPhone} className='phone-icon' />+91 999 510 3430</li>
                  </ul>
                </div>
                <hr className='footer-line mt-5' />
              </div>
              <div className='row mt-3 lazy-load' >
                <div className='col-md-12'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div ref={(el) => elementsRef.current.push(el)}>
                      <span className='copyright ms-2'>
                        Copyright © 2024 MoCA
                        <span className='ms-3'>|</span>
                        <span className='ms-3' id="foot-text"> U67110KL2022PTC073882</span>
                      </span>
                    </div>
                    <div className='d-flex' ref={(el) => elementsRef.current.push(el)}>
                      <a href="https://www.facebook.com/Moca-Fintech-Solutions-102798439245493" target="_blank" rel="noopener noreferrer" className="icon facebook">
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                      <a href="https://www.instagram.com/moca.fintech.solutions/" target="_blank" rel="noopener noreferrer" className="icon instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                      <a href="https://www.linkedin.com/company/moca-fintech-solution-pvt-ltd/" target="_blank" rel="noopener noreferrer" className="icon linkedin">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                      <a href="https://www.youtube.com/channel/UCCTQYtsrOmIbMvtPrv0SbBQ" target="_blank" rel="noopener noreferrer" className="icon youtube">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}
