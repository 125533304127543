import React, { useState,useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faWallet, faMoneyBillWave, faNetworkWired, faChartLine } from '@fortawesome/free-solid-svg-icons'; // Example icons
import useLazyLoad from './useLazyLoad ';
export default function HomeComponent() {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const elementsRef = useLazyLoad();
  
  
    useEffect(() => {
      // Empty effect to trigger Intersection Observer after render
    }, []);
        return (
            <div>
              <div className="homeContainer lazy-load" ref={(el) => elementsRef.current.push(el)}>
                <div className='container home-container'>
                  <div className="row">
                    <div className="col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6">
                      <div className='home-text-content'>
                      <p className='small-text-home text-color ms-2'>WELCOME TO YOUR MOCA
                        <span className='round-buttons-container'>
                          <span className='round-button yellow'></span>
                          <span className='round-button green'></span>
                        </span>
                      </p>
                      <p className='home-heading ms-1'>Seamless Access to Working Capital for MSMEs</p>
                      <div id="hm-btn">
                      <button className="home-button" onClick={toggle}>GET STARTED</button>
                      </div>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
                        <ModalBody>
                          Please contact us at: <strong>+91 999 510 3430</strong>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={toggle}>Close</Button>
                        </ModalFooter>
                      </Modal>
                    </div>
                    </div>
                    <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6'>
                    <div className='home-image'>
                          <img src='/images/img1.png' alt='imge' />
                        </div>       
                    </div>
                  </div>
                  <div className='container service-container'>
  <div className="row">
    <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 containerSection ps-3 lazy-load order-1 order-md-2' ref={(el) => elementsRef.current.push(el)} style={{ backgroundImage: "url('/images/DotGrid.png')", height: "454px" }}>
      <div className='d-flex mt-5 ms-4'>
        <button className="small-button">2022</button>
        <button className="smallyellow-button ms-1">
          <img src="/images/arrow-up-right.svg" alt="icon" />
        </button>
      </div>
      <div className='d-flex text-start justify-content-start ms-4 mt-3'>
        <p id='text-section'>For nearly 3 years, people have<br /> chosen MoCA to help<br /> fund their dreams</p>
      </div>
      <hr className='horizontal-line ms-4 mt-5' />
    </div>
    <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 lazy-load  order-2 order-md-1' ref={(el) => elementsRef.current.push(el)}>
      <div className='home-image'>
        <img src='/images/img2.png' alt='imge' />
      </div>
    </div>
  </div>
</div>

                </div>
              </div>
              <div className='section-three lazy-load' ref={(el) => elementsRef.current.push(el)}>
                <div className='container service-container'>
                  <div className='row'>
                    <div className='col-md-3 col-xs-12 col-sm-12 col-lg-3 col-xl-3 justify-content-center text-left'>
                      <div className="icon-box">
                        <FontAwesomeIcon icon={faWallet} style={{width:"24px",height:"24px"}} color="#9780F2" />
                      </div>
                      <h4 className="mt-3 text-white section-three-header">Effortless Application</h4>
                      <p className="text-gray mt-3 section-three-text">Apply for loans with minimal<br /> documentation</p>
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 col-lg-3 col-xl-3 text-left">
                      <div className="icon-box">                       
                        <img src='/images/money-cash.svg' alt="icon"  color="#9780F2" />
                      </div>
                      <h4 className="mt-3 text-white section-three-header">Competitive Rates</h4>
                      <p className="text-gray mt-3 section-three-text">Transparent and affordable <br /> interest rates</p>
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 col-lg-3 col-xl-3 text-left">
                      <div className="icon-box">
                      <img src='/images/network-spread.svg' alt="icon"  color="#9780F2" />
                      </div>
                      <h4 className="mt-3 text-white section-three-header">Expert Support</h4>
                      <p className="text-gray mt-3 section-three-text">Our team of financial experts is<br /> here to guide you every step</p>
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 col-lg-3 col-xl-3 text-left">
                      <div className="icon-box">
                      <img src='/images/money-cashout.svg' alt="icon"  color="#9780F2" />
                      </div>
                      <h4 className="mt-3 text-white section-three-header">Advanced Security</h4>
                      <p className="text-gray mt-3 section-three-text">Robust security measures to <br />protect your data privacy</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
      );
}
