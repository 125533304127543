import React,{ useState,useEffect }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import useLazyLoad from './useLazyLoad ';
export default function WhyusComponent() {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
      const elementsRef = useLazyLoad();  
      return (
        <div className='whyus-section'>
            <div className='container'>  
              
                  <div className='row'>
                    <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                      <div className='align-items-center aboutSection'>
                        <p className='small-text'>
                          WHY US
                          <span className='round-buttons-container'>
                            <span className='round-button yellow'></span>
                            <span className='round-button green'></span>
                          </span>
                        </p>
                        <p className="about-heading">The Difference<br /> We Make</p>
                        <div className='container'>
                          <p className="about-text">
                            Choosing us means choosing a partner dedicated to your success.<br /> Here’s what sets us apart:
                          </p>
                          <div className='card-whyus mt-5'>
                            <div className='card-body justify-content-center'>
                              <div className='justify-content-center text-left'>
                                <p className='rate-number text-center mb-0'>4.5<span className='rate-subNumber'>/5</span></p>
                                <div className='d-flex justify-content-center mt-0'>
                                  <span className="star">★</span>
                                  <span className="star">★</span>
                                  <span className="star">★</span>
                                  <span className="star">★</span>
                                  <span className="star">★</span>
                                </div>
                                <div className='content'>
                                  <p className='text-name text-left mb-1'>Joji Sunny</p>
                                  <span className="about-text mt-0">Chief Executive Officer</span>
                                  <div className='content-round-circle d-flex justify-content-start mt-3'>
                                    <span className='round-buttons-content-container text-start'>
                                      <button className='d-flex round-buttonContent firstgrape'></button>
                                      <button className='d-flex round-buttonContent grape'>
                                        <img src="/images/arrow-up-right-white.svg" alt="icon" className='arrow-icon' />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
        
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                      <div className='d-flex justify-content-end mt-4 why-us-btn'>
                        <button className="home-button" onClick={toggle}>GET STARTED</button>
                      </div>
                      <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
                        <ModalBody>
                          Please contact us at: <strong>+91 999 510 3430</strong>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={toggle}>Close</Button>
                        </ModalFooter>
                      </Modal>
                      <div className='lists'>
                        <div className='container-list d-flex align-items-center'>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-number'>01</span>
                          </div>
                          <div className='col-md-8 col-xs-8 col-sm-8 col-lg-8 col-xl-8'>
                            <span className='list-text'>Diverse Loan Options</span>
                          </div>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-img'><img src="/images/arrow-up-right-color.svg" alt="icon" /></span>
                          </div>
                        </div>
                        <div className='container-list d-flex align-items-center mt-3'>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-number'>02</span>
                          </div>
                          <div className='col-md-8 col-xs-8 col-sm-8 col-lg-8 col-xl-8'>
                            <span className='list-text'>Transparent and Competitive Rates</span>
                          </div>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-img'><img src="/images/arrow-up-right-color.svg" alt="icon" /></span>
                          </div>
                        </div>
                        <div className='container-list d-flex align-items-center mt-3'>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-number'>03</span>
                          </div>
                          <div className='col-md-8 col-xs-8 col-sm-8 col-lg-8 col-xl-8'>
                            <span className='list-text'>Effortless Online Application</span>
                          </div>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-img'><img src="/images/arrow-up-right-color.svg" alt="icon" /></span>
                          </div>
                        </div>
                        <div className='container-list d-flex align-items-center mt-3'>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-number'>04</span>
                          </div>
                          <div className='col-md-8 col-xs-8 col-sm-8 col-lg-8 col-xl-8'>
                            <span className='list-text'>Commitment to Financial Education</span>
                          </div>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-img'><img src="/images/arrow-up-right-color.svg" alt="icon" /></span>
                          </div>
                        </div>
                        <div className='container-list d-flex align-items-center mt-3'>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-number'>05</span>
                          </div>
                          <div className='col-md-8 col-xs-8 col-sm-8 col-lg-8 col-xl-8'>
                            <span className='list-text'>Personalized Customer Support</span>
                          </div>
                          <div className='col-md-2 col-xs-2 col-sm-2 col-lg-2 col-xl-2'>
                            <span className='list-img'><img src="/images/arrow-up-right-color.svg" alt="icon" /></span>
                          </div>
                        </div>
        
                      </div>
                    </div>
                  </div>
             
              </div>
            </div>
          );
}
