import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';


export default function NavbarComponent() {
    const [active, setActive] = useState('#home');
    const [modal, setModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleItemClick = (id) => {
        setActive(id);
        setIsOpen(false); // Close the menu after clicking a link
    };

    const toggleModal = () => setModal(!modal);
    const toggleMenu = () => setIsOpen(!isOpen);
    
    return (
        <nav className="navbar fixed-top">
  <div className="container">
                <div className='nav-logo'>
                <a href="#home"><img src="logo/Logo.png" width="180" alt="Logo" /></a>
                </div>
       
            <div className="col-md-8 col-xs-10 col-sm-10 col-lg-8 col-xl-8 d-flex" >
            <span id='desktop-view'>
                <ul className="navbar-list">
                    <li className={`navbar-item mt-3 ${active === '#home' ? 'active' : ''}`}>
                        <a href="#home" onClick={() => handleItemClick('#home')}>Home</a>
                    </li>
                    <li className={`navbar-item  mt-3 w-100 ${active === '#about' ? 'active' : ''}`}>
                        <a href="#about" onClick={() => handleItemClick('#about')}>About Us</a>
                    </li>
                    
                    <li className={`navbar-item  mt-3 ${active === '#services' ? 'active' : ''}`}>
                        <a href="#services" onClick={() => handleItemClick('#services')}>Services</a>
                    </li>
                    <li className={`navbar-item  mt-3 w-100 ${active === '#whyus' ? 'active' : ''}`}>
                        <a href="#whyus" onClick={() => handleItemClick('#whyus')}>Why Us</a>
                    </li>
                    <li className={`navbar-item  mt-3 ${active === '#testimonial' ? 'active' : ''}`} >
                        <a href="#testimonial" onClick={() => handleItemClick('#testimonial')}>Testimonial</a>
                    </li>
                    <li className='navbar-item '> <button className="get-started-button d-flex justify-content-end " id='list-btn'  onClick={toggleModal}>GET STARTED</button></li>
                </ul>
              
                </span>
                </div> 
            
                
                
     {/* Mobile Menu Toggle Button */}
     <button className="navbar-toggler justify-content-end align-item-end" onClick={toggleMenu}>
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
            </button>
            {/* Navbar Items for Mobile */}
            <span id="mobile-view">
                <div className={`navbar-collapse ${isOpen ? 'show' : ''}`}>
                    <ul className="navbar-list-mobile justify-content-center">
                        <li className={`navbar-item ${active === '#home' ? 'active' : ''}`}>
                            <Link
                                activeClass="active"
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetActive={() => handleItemClick('#home')}
                            >
                                Home
                            </Link>
                        </li>
                        <li className={`navbar-item ${active === '#about' ? 'active' : ''}`}>
                            <Link
                                activeClass="active"
                                to="about"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetActive={() => handleItemClick('#about')}
                            >
                                About Us
                            </Link>
                        </li>
                        <li className={`navbar-item ${active === '#services' ? 'active' : ''}`}>
                            <Link
                                activeClass="active"
                                to="services"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetActive={() => handleItemClick('#services')}
                            >
                                Services
                            </Link>
                        </li>
                        <li className={`navbar-item ${active === '#contact' ? 'active' : ''}`}>
                            <Link
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetActive={() => handleItemClick('#contact')}
                            >
                                Why Us
                            </Link>
                        </li>
                        <li className={`navbar-item ${active === '#blog' ? 'active' : ''}`}>
                            <Link
                                activeClass="active"
                                to="blog"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onSetActive={() => handleItemClick('#blog')}
                            >
                                Testimonial
                            </Link>
                        </li>
                        <li> <button className="get-started-button w-100 ms-0" onClick={toggleModal}>GET STARTED</button></li>
                    </ul>
                   
               
                
            </div>
                <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Contact Us</ModalHeader>
        <ModalBody>
          Please contact us at: <strong>+91 999 510 3430</strong>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
                 
            </span>
            </div>
        </nav>
    )
}
