import React, { useEffect, useState }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import useLazyLoad from './useLazyLoad ';

export default function TestimonialComponent() {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
    const elementsRef = useLazyLoad();
    return (
        <div className='container'>
          <div className='row '>
            <div className='whyus-section'>
              <div className='row'>
                <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                  <div className='align-items-center aboutSection'>
                    <p className='small-text'>
                      TESTIMONIALS
                      <span className='round-buttons-container'>
                        <span className='round-button yellow'></span>
                        <span className='round-button green'></span>
                      </span>
                    </p>
                    <p className="about-heading">What Our <br />Clients Say</p>
                    <div className='container'>
                      <p className="about-text">Loans empower you to take control of your financial journey and<br/> make significant strides towards success</p>
                      <div className='d-flex justify-content-start mt-4'>
                        <button className="home-button" onClick={toggle}>GET STARTED</button>
                      </div>
                    </div>
                  </div>
                  <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
                        <ModalBody>
                          Please contact us at: <strong>+91 999 510 3430</strong>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={toggle}>Close</Button>
                        </ModalFooter>
                      </Modal>
                </div>
                <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 d-flex flex-wrap'>
                  {[
                    {
                      rate: '4.5',
                      text: 'Thanks to this platform, we secured the funds needed to expand our operations seamlessly.',
                      name: 'Suresh Kumar S',
                      subtext: 'Retail Business Owner',
                    },
                    {
                      rate: '4.5',
                      text: 'The application process was quick and easy, and the support team was incredibly helpful.',
                      name: 'Vishnu',
                      subtext: 'Manufacturing SME Owner',
                    },
                    {
                      rate: '4.5',
                      text: 'With the working capital loan, we managed to keep our cash flow steady during a critical period',
                      name: 'Joji P Sunny',
                      subtext: 'Entrepreneur',
                    },
                    {
                      rate: '4.5',
                      text: 'Securing working capital for my business was a seamless experience thanks to this platform',
                      name: 'Michael',
                      subtext: 'Business Owner',
                    },
                  ].map((testimonial, index) => (
                    <div key={index} className={`testimonial-cards mb-4 ${index % 2 === 1 ? 'ms-4' : ''} lazy-load`} ref={(el) => elementsRef.current.push(el)}>
                      <div className='card-body'>
                        <div className='container'>
                          <div className='d-flex justify-content-between'>
                            <p className='text-left testimonial-rate mt-3'>
                              {testimonial.rate}<span className='testimonial-subrate'>/5</span>
                            </p>
                            <div className='d-flex justify-content-end'>
                              <button className='d-flex testimonial-btn'>
                                <FontAwesomeIcon className='testimonial-icon' icon={faQuoteLeft} />
                              </button>
                            </div>
                          </div>
                          <div className='d-flex justify-content-center'>
                            <p className='testimonial-text mt-5'>{testimonial.text}</p>
                          </div>
                          <div className='d-flex justify-content-end'>
                            <span className='testimonial-name'>{testimonial.name}</span>
                          </div>
                          <p className='d-flex justify-content-end testimonial-subtext mt-2'>{testimonial.subtext}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='starter-container'>
            <div className="row">
              <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 lazy-load' style={{backgroundColor:"#495CA0"}} ref={(el) => elementsRef.current.push(el)}>
           
                <div className='home-testi-image'>
                        <img src='/images/img07.png' alt='imge' />
                        </div>  
             
              </div>
              <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 lazy-load' ref={(el) => elementsRef.current.push(el)}>
                <div className='StartedSection'>
                  <div className='Started-container'>
                    <p className='small-text'>
                      GET STARTED
                      <span className='round-buttons-container'>
                        <span className='round-button yellow'></span>
                        <span className='round-button green'></span>
                      </span>
                    </p>
                    <p className="test-heading">Unlock Your Financial <br />Potential Today!</p>
                    <div className='d-flex justify-content-start mt-4' id='testi-btn'>
                      <button className="home-button" onClick={toggle}>GET STARTED NOW</button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
}
