import React,{useEffect,useState, useRef} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import useLazyLoad from './useLazyLoad ';
export default function AboutComponent() {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const elementsRef = useLazyLoad();
  return (
    <div className='container'>
       <div className='about-section lazy-load' ref={(el) => elementsRef.current.push(el)}>
      <div className='row'>       
          <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 '>
            <div className='align-items-center aboutSection'>
              <p className='small-text'>ABOUT US
                <span className='round-buttons-container'>
                  <span className='round-button yellow'></span>
                  <span className='round-button green'></span>
                </span>
              </p>
              <p className="about-heading">Empowering MSMEs, Building Futures</p>
              <div className='container'>
                <p className="about-text">For years, we have been committed to supporting MSMEs by providing innovative financial solutions tailored to your needs. Our platform simplifies the process of securing working capital, ensuring you can focus on what matters most - running your business.</p>
                <div className='approve-container'>
  <p className='approve-text mt-4'>Approved by</p>
  <div className='container'>
  <div className='row mt-4'>
    <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4'>
      <img src='/images/approve/approve_img4.png' width="150" alt="Approve 1" className="img-fluid" />
    </div>
    <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4 mt-3'>
      <img src='/images/approve/approve_img2.png' alt="Approve 2" className="img-fluid" />
    </div>
    <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4 mt-3'>
      <img src='/images/approve/approve_img3.png' alt="Approve 3" className="img-fluid" />
    </div>
  </div>
</div>

</div>

              </div>
            </div>
          </div>
          <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6'>
          <div className='home-image ms-3'>
                        <img src='/images/img3.png' alt='imge' />
                        </div>  
          </div>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 order-2 order-md-1 lazy-load' ref={(el) => elementsRef.current.push(el)}>
          <div className='container about-gridDot'>
          <div className='home-image mb-4'>
                        <img src='/images/img044.png' alt='imge' />
                        </div>  
          </div>
        </div>
        <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 order-1 order-md-2'>
          <div className='container mt-4 mb-4'>
            <div className='about-content lazy-load' ref={(el) => elementsRef.current.push(el)}>
              <p className='about-content-text'>Online loans, and so much more:</p>
              <div className='d-flex align-items-center loan-section mt-3'>
                <p className='d-flex align-items-center'>
                  <FontAwesomeIcon icon={faCircleCheck} className='check-icon' />
                  <span className='ms-2 check-icon-text'>Effortless Accessibility</span>
                </p>
                <p className='d-flex align-items-center ms-2'>
                  <FontAwesomeIcon icon={faCircleCheck} className='check-icon' />
                  <span className='ms-2 check-icon-text'>Time-Saving Convenience</span>
                </p>
              </div>
              <p className="about-text mt-1">Experience quick approval times, ensuring you get your funds when you need them.</p>
              <button className="home-button mt-5" onClick={toggle}>GET STARTED</button>
              <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
                        <ModalBody>
                          Please contact us at: <strong>+91 999 510 3430</strong>
                        </ModalBody>
                        <ModalFooter>
                          <Button color="secondary" onClick={toggle}>Close</Button>
                        </ModalFooter>
                      </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

