import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { faCoffee, faUser, faChartLine } from '@fortawesome/free-solid-svg-icons';
import useLazyLoad from './useLazyLoad ';
export default function OurServiceComponent() {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const elementsRef = useLazyLoad();
  return (
    <div className='service-section'>
      <div className='container service-container'>
        <div className='row'>
          <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 lazy-load' ref={(el) => elementsRef.current.push(el)}>
            <p className='small-text'>
              OUR SERVICES
              <span className='round-buttons-container'>
                <span className='round-button yellow'></span>
                <span className='round-button green'></span>
              </span>
            </p>
            <p className="about-heading">Comprehensive <br />Financial Solutions for MSMEs</p>
          </div>
          <div className='col-md-6 col-xs-12 col-sm-12 col-lg-6 col-xl-6 d-flex flex-column justify-content-end lazy-load' ref={(el) => elementsRef.current.push(el)}>
            <div className='service-content'>
              <p className="service-text">
                We offer a range of services designed to meet the diverse<br />
                needs of MSMEs:
              </p>
            </div>
            <div className='d-flex justify-content-end mt-3 service-btn'>
              <button className="home-button mt-4" onClick={toggle}>GET STARTED</button>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
              <ModalBody>
                Please contact us at: <strong>+91 999 510 3430</strong>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
        <div className='card-section'>
          <div className='row'>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4 lazy-load' ref={(el) => elementsRef.current.push(el)}>
              <div className='card' style={{height:"420px"}}>
                <div className='card-body text-left'>
                  <div className='card-img' style={{ backgroundImage: "url('/images/DotGrid.png')" }}>
                    <img src='/images/services/Vector.png' alt="icon" />
                    <h5 className='card-title mt-3'>Business Loan</h5>
                    <div className='d-flex flex-column justify-content-end flex-grow-1 card-title'>
                      <p className="d-flex card-subtitle">
                        Quick and easy access to funds for your daily operations
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4 lazy-load' ref={(el) => elementsRef.current.push(el)}>
              <div className='card' style={{height:"420px"}}>
                <div className='card-body text-left'>
                  <div className='card-img' style={{ backgroundImage: "url('/images/DotGrid.png')" }}>
                    <img src='/images/services/wallet.png' alt="icon" />
                    <h5 className='card-title mt-3'>Credit Line</h5>
                    <div className='d-flex flex-column justify-content-end flex-grow-1 card-title'>
                      <p className="d-flex card-subtitle">
                        Flexible credit options to manage your cash flow efficiently.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4 lazy-load' ref={(el) => elementsRef.current.push(el)}>
              <div className='card' style={{height:"420px"}}>
                <div className='card-body text-left'>
                  <div className='card-img' style={{ backgroundImage: "url('/images/DotGrid.png')" }}>
                    <img src='/images/services/globe.png' alt="icon" />
                    <h5 className='card-title mt-3'>Online Loans</h5>
                    <div className='d-flex flex-column justify-content-end flex-grow-1 card-title'>
                      <p className="d-flex card-subtitle">
                        Get the capital you need without the hassle of traditional loan processes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
            <div className='col-md-4 col-xs-12 col-sm-12  col-lg-4 col-xl-4 lazy-load' ref={(el) => elementsRef.current.push(el)}>
              <div className='card' style={{height:"420px"}}>
                <div className='card-body text-left'>
                  <div className='card-img' style={{ backgroundImage: "url('/images/DotGrid.png')" }}>
                    <img src='/images/services/timeline.png' alt="icon" />
                    <h5 className='card-title mt-3'>Term Loans</h5>
                    <div className='d-flex flex-column justify-content-end flex-grow-1 card-title'>
                      <p className="d-flex card-subtitle">
                        Finance your equipment purchases with our tailored loans.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4 lazy-load' ref={(el) => elementsRef.current.push(el)}>
              <div className='card' style={{height:"420px"}}>
                <div className='card-body text-left'>
                  <div className='card-img' style={{ backgroundImage: "url('/images/DotGrid.png')" }}>
                    <img src='/images/services/canvas-dollar.png' alt="icon" />
                    <h5 className='card-title mt-3'>Financial Planning</h5>
                    <div className='d-flex flex-column justify-content-end flex-grow-1 card-title'>
                      <p className="d-flex card-subtitle">
                        Receive personalized financial plans based on your business goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-xs-12 col-sm-12 col-lg-4 col-xl-4 lazy-load' ref={(el) => elementsRef.current.push(el)}>
              <div className='card' style={{height:"420px"}}>
                <div className='card-body text-left'>
                  <div className='card-img' style={{ backgroundImage: "url('/images/DotGrid.png')" }}>
                    <img src='/images/services/list-dropdown.png' alt="icon" />
                    <h5 className='card-title mt-3' >Consultation</h5>
                    <div className='d-flex flex-column justify-content-start' id='consult-card-title'>
                      <span className="d-flex card-subtitle-one mb-3">
                        <ul>
                          <li>Expert financial advice for your business.</li>
                          <li> Save money by choosing the right loan products.</li>
                          <li>Understand and analyze sanction terms.</li>
                          <li>Effective follow-up with banks on technical aspects.</li>
                          <li>Navigate financial agreements with confidence.</li>                      
                        
                        </ul>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
