import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import useLazyLoad from './useLazyLoad ';
export default function SecurityPrivacyComponent() {
  const elementsRef = useLazyLoad();  
  return (
    <div className='privacy-section'>
      <div className='container service-container'>
        <div className='row'>
          <div className='col-md-8 col-xs-9 col-sm-9 col-lg-8 col-xl-8 lazy-load' ref={(el) => elementsRef.current.push(el)}>
            <p className='small-privacytext'>
              SECURITY AND PRIVACY
              <span className='round-buttons-container'>
                <span className='round-button yellow'></span>
                <span className='round-button green'></span>
              </span>
            </p>
            <p className="privacy-heading">
              Your Security and <br />Privacy Matter to Us
            </p>
          </div>
        
          <div className='col-md-4 col-xs-3 col-sm-3 col-lg-4 col-xl-4 d-flex flex-column justify-content-end lazy-load' ref={(el) => elementsRef.current.push(el)}>
            <div className='privacy-content'>
              <div className='d-flex justify-content-end'>
                <button className="d-flex privacy-roundyellow-button ms-1">
                  <img src="/images/arrow-up-right-white.svg" alt="icon" />
                </button>
              </div>
              <p className="privacy-text">
                At our platform, we prioritize the security and privacy of your information. We understand the critical importance of protecting your data and are committed to maintaining the highest standards of security and privacy.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-xs-12 col-sm-12 col-lg-12 col-xl-12 lazy-load' ref={(el) => elementsRef.current.push(el)}>
          <div className='security-image mt-4'>
                        <img src='/images/img5.png' alt='imge' />
                        </div>  
          </div>
        </div>
      </div>
    </div>
  );
}
