import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/NavbarComponent';
import HomeComponent from './components/HomeComponent';
import AboutComponent from './components/AboutComponent';
import OurServiceComponent from './components/OurServiceComponent';
import WhyusComponent from './components/WhyusComponent';
import SecurityPrivacyComponent from './components/SecurityPrivacyComponent';
import TestimonialComponent from './components/TestimonialComponent';
import FooterComponent from './components/FooterComponent';
function App() {
  return (
    <div className="App">
      <NavbarComponent />
      <section id="home" className="section">
        <HomeComponent />
      </section>
      <section id="about" className="section">
        <AboutComponent />
      </section>
      <section id="services" className="section">
        <OurServiceComponent />
      </section>
      <section id="whyus" className="section">
        <WhyusComponent />
        <SecurityPrivacyComponent />
      </section>
      <section id="testimonial" className="section">
        <TestimonialComponent />
      </section>
      <FooterComponent />
    </div>
  );
}

export default App;
